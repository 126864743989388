/*

--------------------------------------------------------------------------------
Basic Oriflame namespace with common module management and debug functionality.
Has to be included as first Oriflame script.
--------------------------------------------------------------------------------

<![CDATA[

*/

( function( $, window ) {

    'use strict';

    if( window.ori ) {
        window.ori.warning(
            "Main Oriflame namespace was already created in window named '%s', location URL '%s'.",
            window.name, window.location.href
        );
        return window.ori;
    }

    /**
     * Simple custom exception.
     */
    function ModuleException( message ) {
        this.name = "ModuleException";
        this.message = message;
        this.hideNameInConsole = true;
    }

    ModuleException.prototype.toString = function() {
        return ( this.hideNameInConsole ? "" : this.name + " : " ) + this.message;
    };

    if( ! $ ) throw new ModuleException( "jQuery must be included before ori modules." );

    var ERROR = 1,
        WARNING = 2,
        INFO = 3,
        LOG = 4,
        OUTPUT_ALERTS = "alerts",
        OUTPUT_CONSOLE = "console",
        OUTPUT_NONE = "none",
        PRINT_SELECTOR = ".js-print",
        ININ_CHAT_BUTTON = ".js-inin-chat-button",
        VALIDATION_ICON = ".k-icon",
        VALIDATION_ICON_SPAN = "<span class='k-icon' />",
        SUCCESS_ICON = "success-icon",
        ERROR_ICON = "error-icon",
        SUCCESS_STYLE = "success",
        ERROR_STYLE = "error";

    // same used in ori.kendo.core.js
    // TODO : maybe we should move this into separate JS file
    function applyJsFixes() {

        // Oriflame non-standard extension
        if( ! ( "formatWith" in String.prototype ) ) {
            String.prototype.formatWith = function( obj ) {
                var str = this,
                    type = Object.prototype.toString.call( obj ).replace( "object ", "" ),
                    i = 0,
                    l = arguments.length,
                    r;
                if( type === "[Object]" && l === 1 ) {
                    for( var prop in obj ) {
                        r = new RegExp( "\{" + prop + "\}", "g" );
                        str = str.replace( r, obj[ prop ] );
                    }
                } else {
                    for( ; i < l; i++ ) {
                        r = new RegExp( "\\{" + i + "\\}", "gi" );
                        str = str.replace( r, arguments[ i ] );
                    }
                }
                return str;
            };
        }

        ////IE Array.prototype.find fix
        var ap = Array.prototype;
        if (!('find' in ap)) {
            Object.defineProperty(ap, "find", {
                enumerable: false,
                configurable: false,
                writable: false,
                value: function (predicate, thisValue) {
                    var arr = Object(this);
                    if (typeof predicate !== "function") {
                        throw new TypeError();
                    }
                    for (var i = 0; i < arr.length; i++) {
                        if (i in arr) {
                            var elem = arr[i];
                            if (predicate.call(thisValue, elem, i, arr)) {
                                return elem;
                            }
                        }
                    }
                    return undefined;
                }
            });
        }

        // older IE console fixes
        var methods = [
            "log", "info", "warn", "error",
            "assert", "dir", "clear", "profile", "profileEnd"
        ];
        if( ! window.console ) {
            console = window.console = {};
            methods.forEach( function( method ) {
                console[ method ] = function() {};
            } );
        } else if( Function.prototype.bind && typeof console.log === "object" ) {
            methods.forEach( function( method ) {
                console[ method ] = this.bind( console[ method ], console );
            }, Function.prototype.call );
        }

    };
    applyJsFixes();

    /**
     * For getting previous debug level either local storage or cookies
     * are used.
     * This detects whether local storage is available.
     */
    function localStorageAvailable() {
        try {
            return 'localStorage' in window && window.localStorage !== null;
        } catch( error ) {
            return false;
        }
    }

    /**
     * This detects whether session storage is available.
     */
    function sessionStorageAvailable() {
        try {
            return 'sessionStorage' in window && window.sessionStorage !== null;
        } catch( error ) {
            return false;
        }
    }

    /**
     * Updates .NET JSON result so that property names in the object start
     * with lower case letters.
     */
    function updateJsonResult( data ) {
        if( typeof data != "object" || ! data ) return data;
        var result;
        if( $.isArray( data ) ) {
            result = [];
            for( var i = 0, l = data.length; i < l; i++ )
                result.push( updateJsonResult( data[ i ] ) );
        } else {
            result = {};
            for( var key in data )
                if( data.hasOwnProperty( key ) )
                    result[ key.substring( 0, 1 ).toLowerCase() + key.substring( 1 ) ] = updateJsonResult( data[ key ] );
        }
        return result;
    }

    /**
     * Helper function to format date in following format :
     * [YYYY-MM-DD ]hh:mm:ss.xxx
     */
    function formatDate( d, fullDate ) {
        if( ! fullDate ) fullDate = false;
        var fd = "";
        var x = "";
        if( fullDate ) {
            fd += d.getFullYear() + "-";
            x = "" + ( d.getMonth() + 1 );
            if( x.length < 2 ) x = "0" + x;
            fd += x + "-";
            x = "" + d.getDate();
            if( x.length < 2 ) x = "0" + x;
            fd += x + " ";
        }
        x = "" + d.getHours();
        if( x.length < 2 ) x = "0" + x;
        fd += x + ":";
        x = "" + d.getMinutes();
        if( x.length < 2 ) x = "0" + x;
        fd += x + ":";
        x = "" + d.getSeconds();
        if( x.length < 2 ) x = "0" + x;
        fd += x + ".";
        x = "" + d.getMilliseconds();
        while( x.length < 3 ) x = "0" + x;
        fd += x;
        return fd;
    }

    /**
     * Returns supposedly proper transition end event name for CSS3 transitions.
     * It has to be checked on existing element, so one is created and then dropped.
     * This is done only the first time the function is called and the value is
     * remembered in internal property for later calls.
     */
    function transitionEndEventName() {
        var element = document.createElement( "div" ),
            transitions = {
                "transition" : "transitionend",
                "OTransition" : "otransitionend", // oTransitionEnd in very old Opera
                "MozTransition" : "transitionend",
                "WebkitTransition" : "webkitTransitionEnd"
            };
        for( var t in transitions )
            if( transitions.hasOwnProperty( t ) && typeof element.style[ t ] !== "undefined" )
                return transitions[ t ];
    }

    /**
     * Helper method for checking whether the script runs inside an iframe.
     */
    function isIframe() {
        var result = false;
        try {
            result = window.self !== window.top;
        } catch( error ) {
            // suppose exception due to crossdomain, than this has to be iframe
            result = true;
        }
        return !!result;
    }

    /**
     * Generates a random sequence of characters, optionally defined length and input chars.
     */
    function generateRandomString(length, chars) {
        length = length || 5;
        chars = chars || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var text = "";

        for (var i = 0; i < length; i++)
            text += chars.charAt(Math.floor(Math.random() * chars.length));

        return text;
    }

    /**
     * Setup ajax request handling.
     */
    function ajaxSetup(errorTitle, errorMessage, unauthorizedTitle) {
        $(document).ajaxError(function ajaxErrorHandler(event, jqxhr, settings, thrownError) {
            // Aborted request
            if (jqxhr.status === 0) {
                return;
            }
            // JSONP error (wrong content returned)
            if (jqxhr.status === 200 && (settings.dataType === 'json' || settings.dataType === 'jsonp') && thrownError.message.search(/jQuery.+was not called/) !== -1) {
                ori.warning('Wrong content or invalid JSON was returned.');
                return;
            }
            // Unauthorized access
            if (jqxhr.status === 401) {
                var href = window.location.href;
                if (href.indexOf("&loggedOut") > 0) {
                    return;
                }

                if (jqxhr.responseJSON === true) { //Add return url
                    var conjuction = href.indexOf("?") < 0 ? "?" : "&";

                    href = href + conjuction +
                    "returnUrl=" + encodeURI(location.pathname + location.search +
                        "&loggedOut");
                }

                ori.redirect.setWindowLocation(href);
                return;
            }

            //ServiceUnavailable (maintenance)
            var errorMsg = errorTitle + '\n' + errorMessage + '\n' + thrownError;
            if(jqxhr.status === 503) {
                ori.error(errorMsg);
                return;
            }

            // Forbidden
            if (jqxhr.status === 403) {
                ori.redirect.setWindowLocation(window.location.protocol + "//" + window.location.host);
                return;
            }

            // General error (changed from popup to console error message)
            if (jqxhr.responseJSON && jqxhr.responseJSON.MessageDetail) {
                errorMsg = errorTitle + '\n' + errorMessage + '\n' + jqxhr.responseJSON.MessageDetail;
            }
            ori.error(errorMsg);
        });
    }

    /**
     * Basic module constructor.
     * @param name Required module name.
     * @param options Optional module settings.
     */
    function Module( name, extension ) {

        /**
         * Simple private property initializations are done here.
         * More complex initializations can be specified in code below.
         */
        var self = this,
            defaults = {
                objectName : "",
                debugLevel : WARNING,
                fullDates : false, // set to true to see also dates in console messages
                outputType : OUTPUT_CONSOLE // OUTPUT_ALERTS, OUTPUT_NONE
            },
            _modules = [];

        // options -------------------------------------------------------------

        self.options = $.extend( {}, defaults );

        /**
         * Sets new options via merging with the existing ones rather then resetting
         * the whole object. Use rather this method than setting the options directly.
         */
        self.setOptions = function( options ) {
            if( options && typeof options == "object" )
                $.extend( true, self.options, options );
        };

        /**
         * Returns value of one particular module option.
         */
        self.option = function( name ) {
            return self.options[ name ];
        };

        /**
         * Sets value of one particular module option.
         */
        self.setOption = function( name, value ) {
            if( typeof name != "string" ) return;
            self.options[ name ] = value;
        };

        // init object name
        if( ! name ) {
            throw new ModuleException( "Problem creating module, empty name specified." );
        }
        self.options.objectName = name;

        /**
         * Module name. Must be specified in module constructor
         * and should not be changed during the lifetime of the object. Object
         * name appears also in debug messages in console.
         */
        self.objectName = self.options.objectName;

        /**
         * Module namespace for binding events etc.
         * Set only once here ( we don't expect this objectName to be changed ).
         */
        self.ns = "." + self.options.objectName;

        // debug messages in console -------------------------------------------

        /**
         * Debug cookie name specified to get / set max debug level on client.
         */
        self.debugCookieName = ( function() {
            return self.options.objectName ?
                (
                    "debug" + self.options.objectName.substr( 0, 1 ).toUpperCase() +
                    self.options.objectName.substr( 1 )
                ) :
                "";
        } )();

        /**
         * We do not store the debug level in this script, just set the default
         * value from local storage or cookie. Can be set in a dedicated page.
         */
        var c = localStorageAvailable() ?
            localStorage[ self.debugCookieName ] :
            $.cookie( self.debugCookieName );
        if( c ) self.options.debugLevel = parseInt( c, 10 );

        /**
         * Maximum debug messages level. Filters debug messages displayed in
         * console. Set to 3 for displaying also trace debug messages.
         */
        self.debugLevel = function() { return self.options.debugLevel; };

        /**
         * Log prefix for debug messages functionality.
         */
        self.logPrefix = function() {
            return formatDate( new Date(), self.options.fullDates ) + " " +
                ( ( self.options.objectName ) ? ( self.options.objectName + " : " ) : "" );
        }

        /**
         * Displays a message in JS console.
         */
        self.print = function( what, args, level ) {
            if( isNaN( level ) ) level = INFO;
            if( level > self.debugLevel() ) return;
            what = self.logPrefix() + what;
            switch( self.options.outputType ) {
                case OUTPUT_NONE :
                    break;
                case OUTPUT_ALERTS :
                    alert( what );
                    break;
                default :
                    if( ! window.console ) return;
                    if( ! args ) args = [];
                    if( ! $.isArray( args ) ) args = [ args ];
                    args.unshift( what );
                    switch( level ) {
                        case ERROR : console.error.apply( console, args ); break;
                        case WARNING : console.warn.apply( console, args ); break;
                        case INFO : console.info.apply( console, args ); break;
                        default : console.log.apply( console, args );
                    }
                    break;
            }
        };

        /**
         * Error level message output. Use probably rather throw than this.
         */
        self.error = function() {
            var args = [].slice.apply( arguments );
            if( ! args.length ) return;
            var what = args.shift();
            self.print( what, args, ERROR );
        };

        /**
         * Warning level message output.
         */
        self.warning = function() {
            var args = [].slice.apply( arguments );
            if( ! args.length ) return;
            var what = args.shift();
            self.print( what, args, WARNING );
        };

        /**
         * Info ( trace ) level message output.
         */
        self.info = function() {
            var args = [].slice.apply( arguments );
            if( ! args.length ) return;
            var what = args.shift();
            self.print( what, args, INFO );
        };

        /**
         * Simple log message output.
         */
        self.log = function() {
            var args = [].slice.apply( arguments );
            if( ! args.length ) return;
            var what = args.shift();
            self.print( what, args, LOG );
        };

        // sub-modules ---------------------------------------------------------

        /**
         * List of current submodules names in order in which they were defined.
         * Each module can be referenced via property with corresponding name.
         */
        self.modules = ( function() { return _modules; } )();

        /**
         * Adds a new submodule into this namespace and internal list of modules.
         */
        self.addModule = function( moduleName, moduleOptions ) {
            if( ! _modules ) _modules = [];
            if( _modules.indexOf( moduleName ) > -1 ) {
                self.warning( "Module '%s' already exists in '%s'.", moduleName, self.objectName );
                return null;
            }
            var created = new Module( moduleName, moduleOptions );
            _modules.push( moduleName );
            self[ moduleName ] = created;
            return created;
        };

        /**
         * Appends all properties from specified object. The object name property is
         * treated differently and can also reset the debug level.
         */
        self.append = function( extension ) {
            if( extension && typeof extension == "object" )
                $.extend( true, self, extension );
        };

        // events --------------------------------------------------------------

        /**
         * Helper object for triggering events for now.
         * TODO : Rewrite modules to jQuery, then this can be done directly on module.
         */
        self.events = $( {} );

        /**
         * Binds event handlers to custom events of the module.
         */
        self.on = function( events, selector, data, handler ) {
            self.events.on( events, selector, data, handler );
            return self;
        };

        /**
         * Binds event handlers to custom events of the module for exactly one execution.
         */
        self.one = function( events, data, handler ) {
            self.events.one( events, data, handler );
            return self;
        };

        /**
         * Unbinds event handlers for custom events of the module.
         */
        self.off = function( events, selector, handler ) {
            self.events.off( events, selector, handler );
            return self;
        };

        /**
         * Triggers custom events of the module.
         */
        self.trigger = function( eventType, extraParameters ) {
            self.events.trigger( eventType, extraParameters );
            return self;
        };

        /**
         * Helper function for building namespaced events string used by jQuery.
         */
        self.eventString = function( eventNames ) {
            if( ! eventNames )
                return "";
            if( $.isArray( eventNames ) )
                return eventNames.join( self.ns + " " ) + self.ns;
            else if( typeof eventNames === "string" )
                return eventNames.split( " " ).join( self.ns + " " ) + self.ns;
            else
                return "";
        };

        /**
         * Helper function for disconnecting module's namespaced events from
         * window on unload. This is usually always the same routine. If not
         * executed, the event handlers may be left on the window object and
         * unexpected results may happen in consequent pages.
         */
        self.disconnectOwnEventsOnWindowUnload = function() {
            var $window = $( window );
            $window.on( "unload" + self.ns, function() { $window.off( self.ns ); } );
        };

        // exceptions ----------------------------------------------------------

        /**
         * Prepares and throws an error object.
         */
        self.exception = function( e ) {
            if( ! e ) return;
            // don't want to have name in debug console when
            // the exception object is only a string
            // self.info( typeof e );
            if( typeof e == "string" )
                e = new ModuleException( e );
            if( e.hideNameInConsole )
                e.message = self.logPrefix() + e.message;
            throw e;
        };

        // common helpers ------------------------------------------------------

        /**
         * Helper property telling whether the script runs inside an iframe.
         */
        self.isIframe = isIframe();

        /**
         * For getting previous debug level either local storage or cookies
         * are used. This detects whether local storage is available.
         */
        self.localStorageAvailable = localStorageAvailable();

        /**
         * This detects whether session storage is available.
         */
        self.sessionStorageAvailable = sessionStorageAvailable();

        /**
         * Generates a random sequence of characters, optionally defined length and input chars.
         */
        self.generateRandomString = generateRandomString;

        /**
         * An enum of Boostrap glyph icons to be used for example in dialogs.
         */
        self.iconEnum = {
                WARNING : "v-icon-round-exclam",
                QUESTION : "v-icon-round-question",
                INFO : "v-icon-round-info"
        };

        /**
         * Uses jQuery to HTML-encode special characters in a string to HTML entities.
         */
        self.htmlEncode = function( value ){
            return $( "<div />" ).text( value ).html();
        };

        /**
         * Uses jQuery to decode HTML entities in a string to "normal" utf characters.
         */
        self.htmlDecode = function( value ) {
            return $( "<div />" ).html( value ).text();
        };

        /**
         * Helper function for escaping quotes and other special characters.
         */
        self.htmlEscape = function( value ) {
            if( typeof value != "string" ) return value;
            return value
                .replace( /\"/g, "&#34;" )
                .replace( /\'/g, "&#39;" )
                .replace( /\\/g, "&#92;" );
        };

        /**
         * Helper function for HTML attributes printout.
         * @param object key - value pairs
         * @return string
         */
        self.htmlAttributes = function(attr) {
            var htmlAttributes = "";

            if (typeof attr == "object" && attr != null) {
                $.each(attr, function(i,v) {
                    if (!attr.hasOwnProperty(i)) {
                        return;
                    }

                    htmlAttributes += ' ' + kendo.htmlEncode(i) + '="' + kendo.htmlEncode(v) + '"';
                });
            }

            return htmlAttributes;
        };

        /**
         * Safely remove HTML tags from input string
         */
        self.removeHtmlTags = function(value) {
            return typeof value === "string" ? value.replace(/<\\?[^>]+(>|$)/g, '') : value;
        };

        /**
         * URL-friendly string
         * @copyright Jakub Vr�na, http://php.vrana.cz/
        */
        self.webalize = function( value ) {
            var s = value.toLowerCase();
            return s.replace(/[^a-z0-9_]+/g, '-').replace(/^-|-$/g, '');
        };

        /**
         * Helper function to format date in following format :
         * [YYYY-MM-DD ]hh:mm:ss.xxx
         */
        self.formatDate = formatDate;

        /**
         * Simple helper method to get first CSS class name from a selector string.
         * Returns empty string when the class name couldn't be found.
         */
        self.cssClass = function( selector ) {
            if( typeof selector != "string" ) return "";
            var result = ( /(\.[\w]*)\w?/i ).exec( selector );
            result = result ? result[ 0 ].replace( /\./, "" ) : "";
            return result;
        };

        /**
         * Simple helper method to get first CSS ID name from a selector string.
         * Returns empty string when the ID name couldn't be found.
         */
        self.cssId = function( selector ) {
            if( typeof selector != "string" ) return "";
            var result = ( /(#[\w]*)\w?/i ).exec( selector );
            result = result ? result[ 0 ].replace( /#/, "" ) : "";
            return result;
        };

        /**
         * Returns name for CSS3 transition end event for current browser.
         */
        self.transitionEndEventName = transitionEndEventName();

        /**
         * Updates .NET JSON result so that property names in the object start
         * with lower case letters.
         */
        self.updateJsonResult = updateJsonResult;

        /**
         * Tries to describe the specified element for better identification.
         */
        self.describeElement = function( element ) {
            var $element = $( element ),
                domElement = $element.get( 0 );
            if( ! domElement ) return "";
            var tag = domElement.nodeName.toLowerCase(),
                type = $element.attr( "type" ) || "",
                value = type ? (
                    $element.is( "[type=checkbox]" ) ?
                    $element.is( ":checked" ).toString() :
                    $element.val().toString()
                ) : "",
                id = $element.attr( "id" ) || "",
                name = $element.attr( "name" ) || "",
                cssClass = $element.attr( "class" ) || "";
            if( type ) type = "[" + type + "]";
            if( id === name ) name = "";
            if( id ) id = "#" + id;
            if( name ) name = "[" + name + "]";
            if( cssClass ) cssClass = "." + cssClass.split( " " ).join( "." );
            if( value ) value = "{" + value + "}";
            return tag + type + name + id + cssClass + value;
        };

        /**
         * Returns URL with ordered parameters for compare.
         */
        self.rebuildUrl = function( url, caseSensitive, uncheckedParams ) {
            // specify all parameters which should be omitted in comparison of URLs
            uncheckedParams = uncheckedParams || [];
            // check whether the domain URL part is present
            if( ! url ) url = "";
            url = unescape( url );
            if( ! caseSensitive ) url = url.toLowerCase();
            if( url.substring( 0, 1 ) == "/" )
                url = unescape( window.location.href )
                    .replace( unescape( window.location.search ), "" )
                    .replace( unescape( window.location.pathname ), "" )
                    + url;
            // parse params
            var urlParts = url.split( "?" ), urlParams = null;
            if( urlParts.length > 1 ) {
                urlParams = urlParts[ 1 ].split( "&" );
                urlParams.sort();
                var urlParamsLength = urlParams.length;
                if( uncheckedParams.length )
                    for( var i = 0; i < urlParamsLength; i++ )
                        for( var j = 0; j < uncheckedParams.length; j++ )
                            if( urlParams[ i ].indexOf( uncheckedParams[ j ] + "=" ) > -1 ) {
                                urlParams.splice( i, 1 );
                                i--; // the array has been changed
                                urlParamsLength--; // the array has been changed
                                break;
                            }
                urlParts[ 1 ] = urlParams.join( "&" );
            }
            // check if all params were spliced
            if( urlParams && urlParams.length > 0 )
                url = urlParts.join( "?" );
            else
                url = urlParts[ 0 ];
            // remove last slash when present
            // TODO : make this dependent on some parameter ?
            if( url.substr( url.length - 1 ) == "/" )
                url = url.substr( 0, url.length - 1 );
            return( url );
        };

        /**
         * Returns true when 2 provided URLs are considered to be the same.
         * URLs are rebuilt before comparing them.
         */
        self.equalUrls = function( url1, url2, caseSensitive, uncheckedParams ) {
            if( ! url1 || ! url2 ) return false;
            if( ( url1 == "#" ) || ( url2 == "#" ) ) return false;
            url1 = self.rebuildUrl( url1, caseSensitive, uncheckedParams );
            url2 = self.rebuildUrl( url2, caseSensitive, uncheckedParams );
            return( url1 == url2 );
        };

        /**
         * Returns value of one particular parameter from given source or empty
         * string when not found. If the source is not specified, location.search
         * will be used.
         */
        self.parameterValue = function( paramName, source ) {
            paramName = paramName
                .replace( /[\[]/, "\\\[" )
                .replace( /[\]]/, "\\\]" );
            source = source || window.location.search;
            var pattern = "[\\?&]" + paramName + "=([^&#]*)",
                regex = new RegExp( pattern ),
                results = regex.exec( source );
            return results ? decodeURIComponent( results[ 1 ].replace( /\+/g, " " ) ) : "";
        };

        /**
         * Gets crossbrowser reference to proper flash object according to its ID.
         */
        self.getFlashObject = function( id ) {
            var $flash = $( "#" + id );
            if( ! $flash.length ) return null;
            if( typeof $flash[ 0 ].SetVariable != "undefined" ) return $flash[ 0 ];
            $flash = $flash.find( "object" );
            return $flash.length ? $flash[ 0 ] : null;
        };

        /**
         * used for displaying error validations on specific element
         */
        self.displayErrorValidation = function($element, success, message) {
            var $parent = $element.parent(),
                $icon = $parent.find(VALIDATION_ICON);

            if (!$icon.length) $icon = $(VALIDATION_ICON_SPAN);
            $parent
                .removeClass(SUCCESS_STYLE + " " + ERROR_STYLE)
                .addClass(success ? SUCCESS_STYLE : ERROR_STYLE);
            $icon
                .removeClass(SUCCESS_ICON + " " + ERROR_ICON)
                .addClass(success ? SUCCESS_ICON : ERROR_ICON)
                .show();
            $icon.appendTo($parent);
            kendo.applyTooltip($icon, { content: message });
        };

        /**
         * Extend functionality with extension logic from constructor call parameter when set.
         */
        self.append( extension );
    }

    // create the top level module ---------------------------------------------

    var ori = window.ori = new Module( "ori" );

    // additional generic functionality available only in ori module -----------

    ori.append( {
        init : function( options ) {
            ori.setOptions( ori.updateJsonResult( options ) );
            if (! ori.options.site || (ori.options.site && ! ori.options.site.isPageEditorEditing)) {
                ori.bindPrintPageEvents();
            }
            if (ori.options.site) {
                ori.constructor.prototype.siteOptions = ori.options.site;
            }
            ajaxSetup(
                options.SiteLocalizations.DialogErrorTitle,
                options.SiteLocalizations.DialogErrorMessage,
                options.SiteLocalizations.DialogUnauthorizedTitle);
        },

        bindPrintPageEvents : function() {
            $(PRINT_SELECTOR).on("click.print", function ( event ) {
                event.preventDefault();
                event.stopPropagation();
                ori.info( "Printing current page ..." );

                //hide inin chat from printing on a page
                $(ININ_CHAT_BUTTON).hide();

                window.print();

                //restore inin chat button once printing window is closed
                $(ININ_CHAT_BUTTON).show();
            } );
        },

        displayDialog: function(title, msg, options, instanceName) {
            var dialogOptions = {
                title: title,
                message: msg,
                resizable: false
            };

            if (typeof instanceName != "undefined") {
                dialogOptions.instanceName = instanceName;
            }

            $.extend(dialogOptions, options);
            $(window).kendoOriDialog(dialogOptions);
        },

        displayError: function(title, msg, options, instanceName, icon) {
            var dialogOptions = {
                icon: icon || ori.iconEnum.WARNING,
                isCancelVisible: false
            };

            $.extend(dialogOptions, options);
            ori.displayDialog(title, msg, dialogOptions, instanceName);
        },

        displayConfirm: function(title, msg, options, instanceName, icon) {
            var dialogOptions = {
                icon: icon || ori.iconEnum.QUESTION,
                isOkVisible: false,
                isCancelVisible: false,
                isYesVisible: true,
                isNoVisible: true
            };

            $.extend(dialogOptions, options);
            ori.displayDialog(title, msg, dialogOptions, instanceName);
        }
    } );

    /*
    ----------------------------------------------------------------------------
    Create utils submodule.
    TODO : This module should be removed, but is referenced at several places for now.
    - popup - preloading images - the only place for this preloading functionality
    - HTML framework seems to reference it some strange way
    ----------------------------------------------------------------------------
    */
    var utils = ori.addModule( "utils", {

        /**
         * Object for external data preloading functionality, like images etc.
         * Can be used to monitor preloading progress via dedicated events.
         * TODO : finalize this
         */
        loader : {
            objects : [],
            objectsRemaining : 0,
            images : [],
            imagesRemaining : 0
        },

        /**
         * Connects loading events according to specified object type and start
         * object data preloading. For now this is meant for non-streaming data.
         * @param options Object describing the preloaded external data and
         * optional callback functions to be called from load event handlers.
         * Properties type and link are required.
         */
        preload : function( options ) {
            if( ! options || ! options.type || ! options.link ) {
                utils.error(
                    "Wrong options for preloading external data.\n" +
                    "Type and link properties have to be specified."
                );
                if( options && options.onError ) options.onError();
                if( options && options.onComplete ) options.onComplete();
                return;
            }
            switch( options.type ) {
                case "image" :
                    utils.preloadImage( options );
                    break;
                default :
                    utils.error(
                        "Wrong external data type : %s. No info about how to preload this.",
                        options.type
                    );
                    if( options && options.onError ) options.onError();
                    if( options && options.onComplete ) options.onComplete();
                    return;
            }
        },

        /**
         * Starts image preloading.
         */
        preloadImage : function( options ) {
            // check options
            if( ! options || ! options.type || ! options.link || options.type != "image" ) {
                utils.error(
                    "Wrong options for preloading external data.\n" +
                    "Type and link properties have to be specified."
                );
                if( options && options.onError ) options.onError();
                if( options && options.onComplete ) options.onComplete();
                return;
            }
            // create image object and apply its load events
            var $image = $( "<img />" )
                .on( "load", function() {
                    options.status = "loaded";
                    utils.imageLoadStatus( $( this ), options );
                } )
                .on( "error", function() {
                    options.status = "error";
                    utils.imageLoadStatus( $( this ), options );
                } )
                .on( "abort", function() {
                    options.status = "aborted";
                    utils.imageLoadStatus( $( this ), options );
                } );
            // TODO : image.complete in cache behavior ?
            utils.loader.objects.push( $image );
            utils.loader.objectsRemaining++;
            utils.loader.images.push( $image );
            utils.loader.imagesRemaining++;
            $image.attr({
                "srcset": options.srcset || "",
                "src": options.link
            });
            if( $image[ 0 ].complete ) {
                utils.info( "Image was already cached in browser before. URL : %s", options.link );
                options.status = "loaded";
                utils.imageLoadStatus( $image, options );
            }
        },

        /**
         * Helper function for updating image load status.
         * @param image jQuery wrapped image object
         * @param options Image preloading options updated with current status
         * and optionally error description etc.
         */
        imageLoadStatus : function( $image, options ) {
            // disconnect load events
            $image.off();
            // update counters in loader
            utils.loader.objectsRemaining--;
            utils.loader.imagesRemaining--;
            // execute callback functions when set according to current status
            switch( options.status ) {
                case "aborted" :
                    if( options.onAbort ) options.onAbort();
                    break;
                case "error" :
                    if( options.onError ) options.onError();
                    break;
                case "loaded" :
                    if( options.onLoad ) options.onLoad();
                    break;
                default :
                    utils.warning( "Unknown image load status : %s", options.status );
            }
            utils.info(
                "Image %s loading is complete, load status is %s, " +
                "remaining objects : %d, remaining images %s.",
                options.link, options.status,
                utils.loader.objectsRemaining, utils.loader.imagesRemaining
            );
            if( options.onComplete ) options.onComplete();
            // TODO : signal events from loader
        },

        /**
         * This function will work cross-browser for loading scripts asynchronously
         * @param src Script source
         * @param successCallback Callback if the script is loaded successfully
         * @param errorCallback Callback if the script loading fails
         */
        loadScriptAsync: function(src, successCallback, errorCallback) {
            var script = document.createElement('script'),
                ready = false,
                firstScript = document.getElementsByTagName('script')[0];
            script.type = 'text/javascript';
            script.src = src;
            script.onload = script.onreadystatechange = function () {
                if (!ready && (!this.readyState || this.readyState === "complete")) {
                    ready = true;
                    if (successCallback) {
                        successCallback();
                    }
                }
            };
            if (errorCallback) {
                script.onerror = errorCallback;
            }
            firstScript.parentNode.insertBefore(script, firstScript);
        },

        /**
         * Checks if the input is visible or not
         */
        isInputVisible: function($input) {
            return $input.is(":visible");
        }
    } );

    ori.info(
        "Created main Oriflame namespace in window named '%s', location URL '%s'.",
        window.name, window.location.href
    );
    return ori;

} )( jQuery, window );

/* ]]> */